import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Projects = (props) => {
    const [propertyData, setPropertyData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [pageImage, setPageImage] = useState("");
    const [stepImage, setStepImage] = useState("");
    const Navigate = useNavigate();
    useEffect(() => {
        props.fetchData();
        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad", translateY: [30, 0]
        });
        anime({
            targets: '[data-anime-1]', // Select elements with the data attribute
            el: "lines", opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad", translateY: [15, 0]
        });
        anime({
            targets: '[data-anime-2]', // Select elements with the data attribute
            e1: "childs", translateY: [15, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-3]', // Select elements with the data attribute
            translateX: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-4]', // Select elements with the data attribute
            e1: "childs", translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-5]', // Select elements with the data attribute
            translateY: [0, 0], opacity: [0, 1], duration: 1200, delay: 100, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-6]', // Select elements with the data attribute
            translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-7]', // Select elements with the data attribute
            translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 200, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-8]', // Select elements with the data attribute
            e1: "childs", translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 300, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-9]', // Select elements with the data attribute
            effect: "slide", color: "#262b35", direction: "tb", delay: 50, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-10]', // Select elements with the data attribute
            translateY: [0, 0], opacity: [0, 1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
    }, [])

    function CheckImage(path) {
        axios.get(path).then(() => { return true; }).catch(() => { return false; });
    }

    function remove_n_words(words, n) {
        if (words != null) {
            return n === 0 ? words : words.split(" ").slice(0, -n).join(" ");
        }
    }

    function getLastNWords(str, n) {
        if (str != null) {
            const words = str.split(' ');
            if (words.length < n) {
                return str; // Return the original string if there are fewer than 2 words
            }
            return words.slice(-n).join(' ');
        }
    }

    useEffect(() => {
        const makeRequest = async () => {
            let jsn = await props.data;
            if (jsn != null) {
                if (jsn.Properties != null) {
                    let parsedArray = JSON.parse(jsn.Properties);
                    setPropertyData(parsedArray);
                }
                if (jsn.Property != null) {
                    if (JSON.parse(jsn.Property).length) {
                        setPageData(JSON.parse(jsn.Property)[0]);
                        console.log(pageData.StepImage);
                        setPageImage(process.env.REACT_APP_API_IMAGE + "/Property/" + pageData.PropertyImage);
                        setStepImage(process.env.REACT_APP_API_IMAGE + "/Property/" + pageData.StepImage);
                    }
                }
            }
        }
        makeRequest();
    }, [props.data]);

    return (
        <Fragment>
            {/*  start page title */}
            <section className="cover-background page-title-big-typography ipad-top-space-margin">
                <div className="container">
                    <div className="row align-items-center align-items-lg-end justify-content-center extra-very-small-screen g-0">
                        <div className="col-xxl-5 col-xl-6 col-lg-7 position-relative page-title-extra-small md-mb-30px md-mt-auto" data-anime>
                            <h1 className="text-base-color">Our Projects</h1>
                            <h2 className="alt-font text-dark-gray fw-500 mb-0 ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">{remove_n_words(pageData.WelcomeHead, 2)}<span className="fw-700 text-highlight d-inline-block">{getLastNWords(pageData.WelcomeHead, 2)}<span className="bg-base-color h-10px bottom-10px opacity-3 separator-animation"></span></span></h2>
                        </div>
                        <div className="col-lg-5 offset-xxl-2 offset-xl-1 border-start border-2 border-color-base-color ps-40px sm-ps-25px md-mb-auto">
                            <span className="d-block w-85 lg-w-100" data-anime-1>{pageData.WelcomeDetails}</span>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end page title */}
            {/*  start section */}
            <section className="overflow-hidden position-relative p-0">
                <div className="opacity-very-light bg-dark-gray z-index-1"></div>
                <div className="container-fluid">
                    <div className="row overlap-height">
                        <div className="col-12 p-0 position-relative overlap-gap-section">
                        {/* demo-real-estate-sell-01.jpg */}
                            <img src="assets/images/demo-real-estate-sell-01.jpg" alt="" className="w-100" />
                            <div className="alt-font fw-600 fs-350 lg-fs-275 md-fs-250 xs-fs-160 position-absolute right-minus-170px lg-right-0px bottom-50px md-bottom-minus-60px xs-bottom-minus-50px text-white text-outline ls-minus-5px lg-right-0px text-outline-width-2px z-index-2" data-bottom-top="transform: translate3d(80px, 0px, 0px);" data-top-bottom="transform: translate3d(-280px, 0px, 0px);">Projects</div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end section */}
            {/*  start section */}
            <section className="bg-very-light-gray z-index-3 position-relative">
                <div className="container">
                    <div className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 overlap-section md-overlap-disable overlap-section-one-fourth justify-content-center" data-anime-2>
                        <div className="col transition-inner-all md-mb-30px">
                            <div className="bg-white h-100 box-shadow-quadruple-large box-shadow-medium-hover border-radius-6px pt-25px pb-25px ps-40px pe-40px lg-p-25px">
                                {/*  start features box item */}
                                <div className="feature-box feature-box-left-icon-middle text-start">
                                    <div className="feature-box-content">
                                        <span className="d-block alt-font fw-600 text-base-color ls-05px">Apartment</span>
                                        <h4 className="d-inline-block text-dark-gray fw-800 ls-minus-1px alt-font mb-0 d-inline-block">{pageData.ApartmentCount}</h4>
                                    </div>
                                    <div className="feature-box-icon me-0">
                                        <img src="assets/images/demo-real-estate-icon-apartment.svg" alt="" />
                                    </div>
                                </div>
                                {/*  end features box item */}
                            </div>
                        </div>
                        <div className="col transition-inner-all">
                            <div className="bg-white h-100 box-shadow-quadruple-large box-shadow-medium-hover border-radius-6px pt-25px pb-25px ps-40px pe-40px lg-p-25px">
                                {/*  start features box item */}
                                <div className="feature-box feature-box-left-icon-middle text-start">
                                    <div className="feature-box-content">
                                        <span className="d-block alt-font fw-600 text-base-color ls-05px">Office</span>
                                        <h4 className="d-inline-block text-dark-gray fw-800 ls-minus-1px me-5px alt-font mb-0 d-inline-block">{pageData.ShopCount}</h4>
                                    </div>
                                    <div className="feature-box-icon me-0">
                                        <img src="assets/images/demo-real-estate-icon-office.svg" alt="" />
                                    </div>
                                </div>
                                {/*  end features box item */}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4 xs-mb-10 pt-9" id="rentals">
                        <div className="col-12 text-center" data-anime-3>
                            <h3 className="alt-font text-dark-gray fw-500 ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">Our <span className="fw-700 text-highlight">Projects<span className="bg-base-color h-10px bottom-10px opacity-3 separator-animation"></span></span></h3>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-xl-3 row-cols-md-2 justify-content-center" data-anime-4>
                        {/*  start box item */}
                        {propertyData.length > 0 && propertyData.map((record, index) => (
                            <div key={index} className="col mb-30px">
                                <div className="border-radius-6px overflow-hidden box-shadow-large">
                                    <div className="image position-relative">
                                        <Link to={"/project/" + record.Name.replace(/ /g, "-")}>
                                            <img src={process.env.REACT_APP_API_IMAGE +"/Property/"+record.Image} alt="" />
                                        </Link>
                                        <div className={record.Status == "Developed" ? "col-auto border-radius-50px ps-15px pe-15px text-uppercase alt-font fw-600 text-white fs-12 lh-24 position-absolute left-20px top-20px bg-orange" : 
                                            record.Status == "Under Development" ? "col-auto border-radius-50px ps-15px pe-15px text-uppercase alt-font fw-600 text-white fs-12 lh-24 position-absolute left-20px top-20px bg-base-color" : 
                                            record.Status == "Sold" ? "col-auto border-radius-50px ps-15px pe-15px text-uppercase alt-font fw-600 text-white fs-12 lh-24 position-absolute left-20px top-20px btn-gradient-flamingo-amethyst-green" : ""}>{record.Status}</div>
                                    </div>
                                    <div className="bg-white">
                                        <div className="content ps-40px pe-40px pt-35px pb-35px md-p-25px border-bottom border-color-transparent-dark-very-light">
                                            <div className="d-flex align-items-center">
                                                <Link to={"/project/" + record.Name.replace(/ /g, "-")} className="alt-font text-dark-gray fw-700 fs-22 me-10px">{record.Name}</Link>
                                            </div>
                                            <p className="mb-20px fs-14">{record.Address}</p>
                                            <div className="row g-0">
                                                <div className="col">
                                                    <div className="d-flex align-items-center">
                                                        <img src="images/demo-real-estate-icon-bed-small.svg" className="me-5px h-20px" alt="" />
                                                        <span className="fw-600 alt-font text-dark-gray">{record.Bed}</span>
                                                    </div>
                                                    <span className="d-block lh-18 fs-15">Bedrooms</span>
                                                </div>
                                                <div className="col">
                                                    <div className="d-flex align-items-center">
                                                        <img src="images/demo-real-estate-icon-bath-small.svg" className="me-5px h-20px" alt="" />
                                                        <span className="fw-600 alt-font text-dark-gray">{record.Bath}</span>
                                                    </div>
                                                    <span className="d-block lh-18 fs-15">Bathrooms</span>
                                                </div>
                                                <div className="col">
                                                    <div className="d-flex align-items-center">
                                                        <img src="images/demo-real-estate-icon-size-small.svg" className="me-5px h-20px" alt="" />
                                                        <span className="fw-600 alt-font text-dark-gray">{record.SqFt} ft<sup>2</sup></span>
                                                    </div>
                                                    <span className="d-block lh-18 fs-15">Living area</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ps-35px pe-35px pt-20px pb-20px md-ps-25px md-pe-25px align-items-center">
                                            <div className="col">
                                                <Link to = {"/project/" + record.Name.replace(/ /g, "-")}  className="btn btn-dark-gray btn-very-small btn-round-edge fw-600">View details</Link>
                                            </div>
                                            <div className="col text-end">
                                                <span className="fs-20 alt-font text-dark-gray fw-700 mb-0">₹ {record.Amount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/*  end box item */}
                    </div>
                </div>
            </section>
            {/*  end section */}
            {/*  start section */}
            <section className="overflow-hidden position-relative overlap-height pb-30px">
                <div className="container overlap-gap-section">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-6 md-mb-50px">
                            <span className="fs-20 d-inline-block mb-15px text-base-color" data-anime-6>{pageData.StepIntro}</span>
                            <h2 className="alt-font fw-500 text-dark-gray ls-minus-1px shadow-none sm-w-80 xs-w-100" data-shadow-animation="true" data-animation-delay="700" data-anime-7>{remove_n_words(pageData.StepHead, 2)} <span className="fw-700 text-highlight">{getLastNWords(pageData.StepHead, 2)}<span className="bg-base-color h-10px bottom-10px opacity-3 separator-animation"></span></span></h2>
                            <div className="row row-cols-1 mt-50px" data-anime-8>
                                {/*  start process step item */}
                                <div className="col-12 process-step-style-05 position-relative hover-box">
                                    <div className="process-step-item d-flex">
                                        <div className="process-step-icon-wrap position-relative">
                                            <div className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-55px w-55px bg-base-color-light alt-font fs-15 fw-600 position-relative">
                                                <span className="number position-relative z-index-1 text-dark-gray">01</span>
                                                <div className="box-overlay bg-base-color rounded-circle"></div>
                                            </div>
                                            <span className="progress-step-separator bg-extra-medium-gray"></span>
                                        </div>
                                        <div className="process-content ps-35px last-paragraph-no-margin mb-30px">
                                            <span className="text-dark-gray fs-19 alt-font mb-5px fw-600 d-block">{pageData.Step1Head}</span>
                                            <p className="w-80 lg-w-100">{pageData.Step1Details}</p>
                                        </div>
                                    </div>
                                </div>
                                {/*  end process step item */}
                                {/*  start process step item */}
                                <div className="col-12 process-step-style-05 position-relative hover-box">
                                    <div className="process-step-item d-flex">
                                        <div className="process-step-icon-wrap position-relative">
                                            <div className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-55px w-55px bg-base-color-light alt-font fs-15 fw-600 fw-600 position-relative">
                                                <span className="number position-relative z-index-1 text-dark-gray">02</span>
                                                <div className="box-overlay bg-base-color rounded-circle"></div>
                                            </div>
                                            <span className="progress-step-separator bg-extra-medium-gray"></span>
                                        </div>
                                        <div className="process-content ps-35px last-paragraph-no-margin mb-30px">
                                            <span className="text-dark-gray fs-19 alt-font mb-5px fw-600 d-block">{pageData.Step2Head}</span>
                                            <p className="w-80 lg-w-100">{pageData.Step2Details}</p>
                                        </div>
                                    </div>
                                </div>
                                {/*  end process step item */}
                                {/*  start process step item */}
                                <div className="col-12 process-step-style-05 position-relative hover-box">
                                    <div className="process-step-item d-flex">
                                        <div className="process-step-icon-wrap position-relative">
                                            <div className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-55px w-55px bg-base-color-light alt-font fs-15 fw-600 fw-600 position-relative">
                                                <span className="number position-relative z-index-1 text-dark-gray">03</span>
                                                <div className="box-overlay bg-base-color rounded-circle"></div>
                                            </div>
                                        </div>
                                        <div className="process-content ps-35px last-paragraph-no-margin mb-30px">
                                            <span className="text-dark-gray fs-19 alt-font mb-5px fw-600 d-block">{pageData.Step3Head}</span>
                                            <p className="w-80 lg-w-100">{pageData.Step3Details}</p>
                                        </div>
                                    </div>
                                </div>
                                {/*  end process step item */}
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 position-relative" data-anime-9>
                            <figure className="position-relative m-0">
                                <img src={stepImage} alt="" />
                                <figcaption className="position-absolute top-50 translate-middle-y left-0px d-none d-md-block">
                                    <img src="assets/images/demo-real-estate-sell-03.webp" className="animation-float" alt="" />
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end section */}

        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return { data: state.dCandidate.list };
};

const mapActionToProps = {
    fetchData: actions.fetchallProperty
}

export default connect(mapStateToProps, mapActionToProps)(Projects);