import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

const Home = (props) => {
    const [slideData, setSlideData] = useState([]);
    const [aboutData, setAboutData] = useState([]);
    const [propertyData, setPropertyData] = useState([]);
    const [phoneNo, setPhoneNo] = useState("");
    const [processData, setProcessData] = useState([]);
    const [testoData, setTestData] = useState([]);
    const [video, setVideo] = useState("");
    const settings = { dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, autoplay: true };

    useEffect(() => {
        props.fetchAllCandidates()
        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", opacity: [0, 1], duration: 600, delay: 0, staggervalue: 15, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-feature]', // Select elements with the data attribute
            el: "childs", opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad", translateY: [30, 0]
        });
        anime({
            targets: '[data-anime-feature-main]', // Select elements with the data attribute
            el: "childs", opacity: [0, 1], duration: 600, delay: 0, staggervalue: 200, easing: "easeOutQuad", "translateX": [-15, 0], translateY: [15, 0]
        });
    }, [])

    useEffect(() => {
        const makeRequest = async () => {
            let jsn = await props.data;
            if (jsn != null) {
                if (jsn.Slides != null) {
                    let parsedArray = JSON.parse(jsn.Slides);
                    setSlideData(parsedArray);
                }
                if (jsn.About != null) {
                    if (JSON.parse(jsn.About).length) {
                        setAboutData(JSON.parse(jsn.About)[0]);
                    }
                }
                if (jsn.Properties != null) {
                    let parsedArray = JSON.parse(jsn.Properties);
                    setPropertyData(parsedArray);
                }
                if (jsn.HomeProcess != null) {
                    if (JSON.parse(jsn.HomeProcess).length) {
                        setProcessData(JSON.parse(jsn.HomeProcess)[0]);
                    }
                }
                if (jsn.Testonomials != null) {
                    let parsedArray = JSON.parse(jsn.Testonomials);
                    setTestData(parsedArray);
                }
                if (jsn.Video != null) {
                    setVideo(jsn.Video);
                }
            }
        }
        makeRequest();
    }, [props.data]);

    useEffect(() => {
        setPhoneNo(aboutData.phoneNo);
    }, [slideData]);

    return (
        <Fragment>
            <section id="slider" className="p-0 top-space-margin ">
                <div className="demo-corporate-slider_wrapper fullscreen-container" data-alias="portfolio-viewer" data-source="gallery" style={{ backgroundColor: "transparent", padding: "0px" }}>
                    <div id="demo-corporate-slider" className="rev_slider bg-regal-blue fullscreenbanner" style={{ display: "none" }} data-version="5.3.1.6">
                        <ul>
                        <Slider {...settings}>
                            {slideData.map((record, index) => (
                                <li data-index={index} data-transition="parallaxleft" data-slotamount="default"
                                data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                                data-easeout="default" data-masterspeed="1500" data-rotate="0" data-saveperformance="off"
                                data-title="Crossfit" data-param1="" data-param2="" data-param3="" data-param4=""
                                data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10=""
                                data-description="">
                                
                                <img src={process.env.REACT_APP_API_IMAGE + "/Slides/"+ record.Image} alt="Image"
                                    data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                    className="rev-slidebg" data-no-retina style={{height: "600px", width: "100%"}}/>
                                
                                <div className="tp-caption tp-shape tp-shapewrapper " id="slide-1-layer-01"
                                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                    data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                    data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                    data-frames='[{"delay":0,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},
                                     {"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power4.easeInOut"}]' style={{ background: "rgba(22,35,63,0.1)", zIndex: 0 }}>
                                </div>
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-regal-blue border-radius-50"
                                    id="slide-1-layer-02" data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['0','0','0','0']" data-width="['900','700','700','600']"
                                    data-height="['900','700','700','600']" data-whitespace="nowrap" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1000,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:0.5;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-regal-blue border-radius-50"
                                    id="slide-1-layer-03" data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['0','0','0','0']" data-width="['1200','1000','900','800']"
                                    data-height="['1200','1000','900','800']" data-whitespace="nowrap" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1300,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:0.3;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                <div id="rrzm_638" className="rev_row_zone rev_row_zone_middle">
                                    <div className="tp-caption  " id="slide-1-layer-04" data-x="['left','left','left','left']"
                                        data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                        data-voffset="['-426','-426','-426','-426']" data-width="none" data-height="none"
                                        data-whitespace="nowrap" data-type="row" data-columnbreak="3"
                                        data-responsive_offset="on" data-responsive="off"
                                        data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                        data-paddingtop="[0,0,0,0]" data-paddingright="[100,75,50,30]"
                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[100,75,50,30]">
                                        <div className="tp-caption" id="slide-1-layer-05" data-x="['left','left','left','left']"
                                            data-hoffset="['100','100','100','100']" data-y="['top','top','top','top']"
                                            data-voffset="['100','100','100','100']" data-width="none" data-height="none"
                                            data-whitespace="nowrap" data-type="column" data-responsive_offset="on"
                                            data-responsive="off"
                                            data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                            data-columnwidth="100%" data-verticalalign="top"
                                        >
                                            <div className="tp-caption mx-auto text-uppercase" id="slide-1-layer-06"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['13','13','13','13']"
                                                data-lineheight="['20','20','20','20']"
                                                data-fontweight="['500','500','500','500']"
                                                data-letterspacing="['1','1','1','1']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['800','auto','auto','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="off"
                                                data-frames='[{"delay":2500,"speed":800,"frame":"0","from":"y:-50px;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"power3.inOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"power3.inOut"}]'
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[25,25,10,10]" data-paddingleft="[0,0,0,0]"
                                                style={{ wordBreak: "initial" }}>
                                                <i className="bi bi-award pe-5px icon-small"></i>{record.Header}
                                            </div>
                                            <div className="tp-caption mx-auto" id="slide-1-layer-07"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['75','60','70','50']"
                                                data-lineheight="['70','65','75','55']"
                                                data-fontweight="['700','700','700','700']"
                                                data-letterspacing="['-2','-2','-2','0']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['700','600','600','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="on"
                                                data-frames='[{"delay":"1500","split":"chars","splitdelay":0.03,"speed":800,"split_direction":"middletoedge","frame":"0","from":"x:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":100,"frame":"999","to":"opacity:0;fb:0;","ease":"Power4.easeOut"}]'
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[33,28,35,25]" data-paddingleft="[0,0,0,0]"
                                                style={{ wordBreak: "initial", textShadow: "#0b1236 3px 3px 15px" }}>
                                                {record.ShortLine1}
                                            </div>
                                            <div className="tp-caption mx-auto" id="slide-1-layer-08"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']"
                                                data-y="['middle','middle','middle','middle']"
                                                data-voffset="['0','0','0','0']" data-fontsize="['20','20','24','20']"
                                                data-lineheight="['36','36','40','30']"
                                                data-fontweight="['300','300','300','300']"
                                                data-letterspacing="['0','0','0','0']"
                                                data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                                data-width="['500','500','auto','auto']" data-height="auto"
                                                data-whitespace="normal" data-basealign="grid" data-type="text"
                                                data-responsive_offset="off" data-verticalalign="middle"
                                                data-responsive="on"
                                                data-frames='[{"delay":2500,"speed":800,"frame":"0","from":"y:50px;opacity:0;fb:20px;","to":"o:0.6;fb:0;","ease":"power3.inOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"power3.inOut"}]'
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                data-paddingbottom="[36,36,60,40]" data-paddingleft="[0,0,0,0]">
                                                {record.ShortLine2}
                                            </div>
                                            <div className="tp-caption tp-resizeme" id="slide-1-layer-09"
                                                data-x="['center','center','center','center']"
                                                data-hoffset="['0','0','0','0']" data-y="['top','top','top','top']"
                                                data-voffset="['0','0','0','0']" data-width="auto" data-height="none"
                                                data-whitespace="nowrap" data-fontsize="['18','16','16','16']"
                                                data-lineheight="['70','55','55','55']" data-type="text"
                                                data-responsive_offset="off" data-responsive="off"
                                                data-frames='[{"delay":3000,"speed":1000,"frame":"0","from":"y:100px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                data-paddingtop="[0,0,0,0]" data-paddingright="[75,70,65,60]"
                                                data-paddingbottom="[0,0,0,0]" data-paddingleft="[45,35,30,30]">
                                                <a href="contact"
                                                    className="btn btn-extra-large get-started-btn btn-rounded with-rounded btn-gradient-flamingo-amethyst-green btn-box-shadow">Book now<span className="bg-white text-base-color"><i
                                                        className="fa-solid fa-arrow-right"></i></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme bg-base-color border-radius-50"
                                    id="slide-1-layer-10" data-x="['center','center','center','center']"
                                    data-hoffset="['510','410','310','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['-320','-250','-250','0']" data-width="['122','122','120','120']"
                                    data-height="['122','122','120','120']" data-visibility="['on','on','off','off']"
                                    data-whitespace="nowrap" data-basealign="grid" data-type="shape"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":3500,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:1;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ zIndex: 0 }}>
                                </div>
                                <div className="tp-caption d-inline-block" id="slide-1-layer-11"
                                    data-x="['center','center','center','center']" data-hoffset="['510','410','310','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-305','-250','-250','0']"
                                    data-visibility="['on','on','off','off']" data-fontsize="['13','13','13','13']"
                                    data-lineheight="['16','16','16','16']" data-fontweight="['500','600','600','600']"
                                    data-letterspacing="['0','0','0','0']"
                                    data-color="['#ffffff','#ffffff','#ffffff','#ffffff']"
                                    data-width="['100','100','100','100']" data-height="auto" data-whitespace="normal"
                                    data-basealign="grid" data-type="text" data-responsive_offset="on"
                                    data-verticalalign="middle" data-responsive="on"
                                    data-frames='[{"delay":3700,"speed":1000,"frame":"0","from":"x:0px;y:50px;rX:0deg;rY:0deg;rZ:0deg;sX:0.5;sY:0.5;opacity:0;","to":"o:1;","ease":"Back.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                    data-paddingright="[0,0,0,0]" data-paddingbottom="[33,0,0,0]"
                                    data-paddingleft="[0,0,0,0]" style={{ wordBreak: "initial" }}>
                                    <i className="bi bi-patch-check-fill icon-extra-medium d-block pb-10px"></i> <span
                                        className="d-block text-uppercase"></span>
                                </div>
                            </li>
                            ))
                            }
                        </Slider>
                        </ul>
                        <div className="tp-bannertimer"
                            style={{ height: "10px", backgroundColor: "rgba(0, 0, 0, 0.10)", zIndex: "98" }}></div>
                    </div>
                </div>
            </section>
            <section id="feature" className="border-bottom border-color-extra-medium-gray pt-40px pb-40px overflow-hidden">
                <div className="container">
                    <div className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center align-items-center" data-anime-feature-main>
                        {/* start features box item */}
                        <div className="col icon-with-text-style-08 md-mb-30px text-center text-sm-start">
                            <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
                                <div className="feature-box-icon me-10px">
                                    <i className="bi bi-shield-check icon-very-medium text-base-color"></i>
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-500 text-dark-gray d-block lh-26">Life Style Integrated</span>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                        {/* start features box item */}
                        <div className="col icon-with-text-style-08 md-mb-30px text-center text-sm-start">
                            <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
                                <div className="feature-box-icon me-10px">
                                    <i className="bi bi-hourglass icon-very-medium text-base-color"></i>
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-500 text-dark-gray d-block lh-26">Experienced Team</span>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                        {/* start features box item */}
                        <div className="col icon-with-text-style-08 xs-mb-30px text-center text-sm-start">
                            <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
                                <div className="feature-box-icon me-10px">
                                    <i className="bi bi-pin-map icon-very-medium text-base-color"></i>
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-500 text-dark-gray d-block lh-26">Best Location</span>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                        {/* start features box item */}
                        <div className="col icon-with-text-style-08 text-center text-sm-start">
                            <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
                                <div className="feature-box-icon me-10px">
                                    <i className="bi bi-briefcase icon-very-medium text-base-color"></i>
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-500 text-dark-gray d-block lh-26">Value For Money</span>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                    </div>
                </div>
            </section>
            {/* about */}
            {aboutData == null ? "" :
                <section id='about'>
                    <div className="container">
                        <div className="row justify-content-center align-items-center mb-6 sm-pb-9">
                            <div className="col-lg-6 col-md-9 position-relative md-mb-15 text-center text-lg-start"
                                data-anime>
                                <div className="absolute-middle-center z-index-9 counter-style-02 text-center">
                                    <span className="fs-160 fw-800 text-dark-gray ls-minus-10px xs-ls-minus-5px position-relative lg-fs-130 xs-fs-75">{aboutData.Year}<sub className="align-top fs-80 lg-fs-70 text-dark-gray position-relative top-minus-3px">+</sub></span>
                                    <span className="d-block mx-auto fs-20 fw-500 lh-26 w-70 text-center text-dark-gray xs-w-100">Years working experience</span>
                                </div>
                                <img src="/assets/images/demo-corporate-03.png" alt="" />
                                <img src={process.env.REACT_APP_API_IMAGE + "/Home/"+ aboutData.Image} className="position-absolute top-50 left-minus-100px lg-left-minus-40px sm-left-minus-30px lg-w-50 sm-w-55" data-bottom-top="transform: translateY(50px)" data-top-bottom="transform: translateY(-220px)" alt="" />
                                <img src="/assets/images/demo-corporate-02.png" className="position-absolute top-0px xl-top-minus-10px w-170px right-20px md-right-40px xs-w-40" data-bottom-top="transform: translateY(-50px)" data-top-bottom="transform: translateY(50px)" alt="" />
                            </div>
                            <div className="col-lg-6 ps-6 text-center text-lg-start lg-ps-15px" data-anime-feature>
                                <span className="ps-25px pe-25px mb-20px text-uppercase text-base-color fs-14 lh-42px fw-700 border-radius-100px bg-gradient-very-light-gray-transparent d-inline-block">{aboutData.ShortHead}</span>
                                <h3 className="text-dark-gray fw-700 ls-minus-1px">{aboutData.Head}</h3>
                                <p className="w-80 xl-w-90 lg-w-100 mb-40px sm-mb-25px">{aboutData.Details}</p>
                                <a href="about.html" className="btn btn-large btn-dark-gray btn-hover-animation-switch btn-box-shadow btn-rounded me-25px xs-me-0">
                                    <span>
                                        <span className="btn-text">Read about us</span>
                                        <span className="btn-icon"><i className="feather icon-feather-arrow-right"></i></span>
                                        <span className="btn-icon"><i className="feather icon-feather-arrow-right"></i></span>
                                    </span>
                                </a>
                                <span className="text-dark-gray fw-700 ls-minus-05px d-block d-sm-inline-block sm-mt-15px"><a href={"tel:" + phoneNo}><i className="feather icon-feather-phone-call me-10px"></i>{aboutData.PhoneNo}</a></span>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <section className="p-0 border-top border-color-extra-medium-gray">
                <div className="container">
                    <div className="row row-cols-1 row-cols-lg-6 row-cols-md-3 row-cols-sm-2 justify-content-center g-0 bg-white" data-anime='{ "el": "childs", "translateX": [50, 0], "opacity": [0,1], "duration": 1200, "delay": 0, "staggervalue": 150, "easing": "easeOutQuad" }'>
                        {/* start features box item */}
                        <div className="col d-flex align-items-center text-center text-lg-start icon-with-text-style-04 border-end md-border-start md-border-bottom sm-border-start-0 xs-border-end-0 border-color-extra-medium-gray position-relative xs-pt-60px xs-pb-60px">
                            <span className="fs-26 lh-30 d-inline-block alt-font text-dark-gray fw-600 mb-0 w-100 sm-w-85 mx-auto shadow-none" data-shadow-animation="true" data-animation-delay="700">What are you <span className="fw-600 text-highlight">looking<span className="bg-base-color h-6px bottom-3px opacity-3 separator-animation"></span></span> for?</span>
                        </div>
                        {/* end features box item */}
                        {/* start features box item */}
                        <div className="col icon-with-text-style-04 border-end md-border-bottom sm-border-end-0 border-color-extra-medium-gray position-relative">
                            <div className="feature-box pt-45px pb-25px">
                                <div className="feature-box-icon mb-10px">
                                    {/* <div className="text-uppercase alt-font fw-700 text-base-color fs-12 lh-22 bg-base-color-transparent border-radius-4px position-absolute left-18px top-18px ps-10px pe-10px">20K</div> */}
                                    <a href="demo-real-estate-sell.html" className="d-block">
                                        <img src="assets/images/demo-real-estate-icon-apartment.svg" alt="" />
                                    </a>
                                </div>
                                <div className="feature-box-content">
                                    <a href="demo-real-estate-sell.html" className="alt-font text-dark-gray text-dark-gray-hover fw-700 fs-15 lh-20 text-uppercase">Apartment</a>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                        {/* start features box item */}
                        <div className="col icon-with-text-style-04 border-end xs-border-end-0 xs-border-bottom border-color-extra-medium-gray position-relative">
                            <div className="feature-box pt-45px pb-25px">
                                <div className="feature-box-icon mb-10px">
                                    {/* <div className="text-uppercase alt-font fw-700 text-base-color fs-12 lh-22 bg-base-color-transparent border-radius-4px position-absolute left-18px top-18px ps-10px pe-10px">09K</div> */}
                                    <a href="demo-real-estate-sell.html" className="d-block">
                                        <img src="assets/images/demo-real-estate-icon-office.svg" alt="" />
                                    </a>
                                </div>
                                <div className="feature-box-content">
                                    <a href="demo-real-estate-sell.html" className="alt-font text-dark-gray text-dark-gray-hover fw-700 fs-15 lh-20 text-uppercase">Office</a>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                        {/* start features box item */}
                        <div className="col icon-with-text-style-04 border-color-extra-medium-gray md-border-end sm-border-end-0 position-relative">
                            <div className="feature-box pt-45px pb-25px">
                                <div className="feature-box-icon mb-10px">
                                    {/* <div className="text-uppercase alt-font fw-700 text-base-color fs-12 lh-22 bg-base-color-transparent border-radius-4px position-absolute left-18px top-18px ps-10px pe-10px">20K</div> */}
                                    <a href="demo-real-estate-sell.html" className="d-block">
                                        <img src="assets/images/demo-real-estate-icon-shop.svg" alt="" />
                                    </a>
                                </div>
                                <div className="feature-box-content">
                                    <a href="demo-real-estate-sell.html" className="alt-font text-dark-gray text-dark-gray-hover fw-700 fs-15 lh-20 text-uppercase">Shop</a>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                    </div>
                </div>
            </section>
            {/* properties */}
            <section className="bg-very-light-gray">
                <div className="container">
                    <div className="row align-items-center mb-6 xs-mb-8">
                        <div className="col-md-8 text-center text-md-start sm-mb-20px" data-anime='{ "translateX": [-30, 0], "opacity": [0,1], "duration": 1200, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <h3 className="alt-font text-dark-gray fw-500 mb-0 ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">Properties <span className="fw-700 text-highlight d-inline-block">we build<span className="bg-base-color h-10px bottom-1px opacity-3 separator-animation"></span></span></h3>
                        </div>
                        <div className="col-md-4" data-anime='{ "translateX": [30, 0], "opacity": [0,1], "duration": 1200, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <div className="d-flex justify-content-center justify-content-md-end">
                                <a href="demo-real-estate-sell.html" className="fw-600 alt-font text-dark-gray text-dark-gray-hover d-flex align-items-center">View all property<span className="d-flex align-items-center justify-content-center bg-dark-gray h-40px w-40px text-center rounded-circle fs-16 text-white ms-10px"><i className="feather icon-feather-arrow-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-xl-3 row-cols-md-2 justify-content-center" data-anime='{ "el": "childs", "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                        {/* start box item */}
                        {propertyData.length > 0 && propertyData.map((record, index) => (
                            <div key={index} className="col mb-30px">
                                <div className="border-radius-6px overflow-hidden box-shadow-large">
                                    <div className="image position-relative">
                                        <Link to={"/project/" + record.Name.replace(/ /g, "-")}>
                                            <img src={process.env.REACT_APP_API_IMAGE + "/Property/" + record.Image} alt="" />
                                        </Link>
                                        <div className={record.Status == "Developed" ? "col-auto border-radius-50px ps-15px pe-15px text-uppercase alt-font fw-600 text-white fs-12 lh-24 position-absolute left-20px top-20px bg-orange" :
                                            record.Status == "Under Development" ? "col-auto border-radius-50px ps-15px pe-15px text-uppercase alt-font fw-600 text-white fs-12 lh-24 position-absolute left-20px top-20px bg-base-color" :
                                                record.Status == "Sold" ? "col-auto border-radius-50px ps-15px pe-15px text-uppercase alt-font fw-600 text-white fs-12 lh-24 position-absolute left-20px top-20px btn-gradient-flamingo-amethyst-green" : ""}>{record.Status}</div>
                                    </div>
                                    <div className="bg-white">
                                        <div className="content ps-40px pe-40px pt-35px pb-35px md-p-25px border-bottom border-color-transparent-dark-very-light">
                                            <div className="d-flex align-items-center">
                                                <Link to={"/project/" + record.Name.replace(/ /g, "-")} className="alt-font text-dark-gray fw-700 fs-22 me-10px">{record.Name}</Link>
                                            </div>
                                            <p className="mb-20px">{record.Address}</p>
                                            <div className="row g-0">
                                                <div className="col">
                                                    <div className="d-flex align-items-center">
                                                        <img src="assets/images/demo-real-estate-icon-bed-small.svg" className="me-5px h-20px" alt="" />
                                                        <span className="fw-600 alt-font text-dark-gray">{record.Bed}</span>
                                                    </div>
                                                    <span className="d-block lh-18 fs-15">Bedrooms</span>
                                                </div>
                                                <div className="col">
                                                    <div className="d-flex align-items-center">
                                                        <img src="assets/images/demo-real-estate-icon-bath-small.svg" className="me-5px h-20px" alt="" />
                                                        <span className="fw-600 alt-font text-dark-gray">{record.Bath}</span>
                                                    </div>
                                                    <span className="d-block lh-18 fs-15">Bathrooms</span>
                                                </div>
                                                <div className="col">
                                                    <div className="d-flex align-items-center">
                                                        <img src="assets/images/demo-real-estate-icon-size-small.svg" className="me-5px h-20px" alt="" />
                                                        <span className="fw-600 alt-font text-dark-gray">{record.SqFt} ft<sup>2</sup></span>
                                                    </div>
                                                    <span className="d-block lh-18 fs-15">Living area</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ps-35px pe-35px pt-20px pb-20px md-ps-25px md-pe-25px align-items-center">
                                            <div className="col">
                                                <Link to={"/project/" + record.Name.replace(/ /g, "-")} className="btn btn-dark-gray btn-very-small btn-round-edge fw-600">View details</Link>
                                            </div>
                                            <div className="col text-end">
                                                <span className="fs-18 alt-font text-dark-gray fw-700 mb-0">₹ {record.Amount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* end box item */}
                    </div>
                </div>
            </section>
            {/* process */}
            <section>
                <div className="container position-relative">
                    <div className="row align-items-center mb-7">
                        <div className="col-xxl-4 col-lg-5 md-mb-15 sm-mb-20 text-center text-lg-start">
                            <span className="ps-25px pe-25px mb-20px text-uppercase text-base-color fs-14 lh-42px fw-700 border-radius-100px bg-gradient-very-light-gray-transparent d-inline-block">{processData.ShortHead}</span>
                            <h3 className="text-dark-gray fw-700 ls-minus-2px mb-40px">{processData.Head}</h3>
                            <div className="row row-cols-1" data-anime='{ "el": "childs", "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                {/* start process step item */}
                                <div className="col-12 process-step-style-05 position-relative hover-box">
                                    <div className="process-step-item d-flex">
                                        <div className="process-step-icon-wrap position-relative">
                                            <div className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-60px w-60px fs-14 bg-light-red fw-700 position-relative">
                                                <span className="number position-relative z-index-1 text-dark-gray">01</span>
                                                <div className="box-overlay bg-base-color rounded-circle"></div>
                                            </div>
                                            <span className="progress-step-separator bg-dark-gray opacity-1"></span>
                                        </div>
                                        <div className="process-content ps-30px last-paragraph-no-margin mb-30px">
                                            <span className="d-block fw-700 text-dark-gray mb-5px fs-18">{processData.Step1Head}</span>
                                            <p className="w-90 lg-w-100 lh-32">{processData.Step1Details}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end process step item */}
                                {/* start process step item */}
                                <div className="col-12 process-step-style-05 position-relative hover-box">
                                    <div className="process-step-item d-flex">
                                        <div className="process-step-icon-wrap position-relative">
                                            <div className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-60px w-60px fs-14 bg-light-red fw-700 position-relative">
                                                <span className="number position-relative z-index-1 text-dark-gray">02</span>
                                                <div className="box-overlay bg-base-color rounded-circle"></div>
                                            </div>
                                            <span className="progress-step-separator bg-dark-gray opacity-1"></span>
                                        </div>
                                        <div className="process-content ps-30px last-paragraph-no-margin mb-30px">
                                            <span className="d-block fw-700 text-dark-gray mb-5px fs-18">{processData.Step2Head}</span>
                                            <p className="w-90 lg-w-100 lh-32">{processData.Step2Details}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end process step item */}
                                {/* start process step item */}
                                <div className="col-12 process-step-style-05 position-relative hover-box xs-mb-30px">
                                    <div className="process-step-item d-flex">
                                        <div className="process-step-icon-wrap position-relative">
                                            <div className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-60px w-60px fs-14 bg-light-red fw-700 position-relative">
                                                <span className="number position-relative z-index-1 text-dark-gray">03</span>
                                                <div className="box-overlay bg-base-color rounded-circle"></div>
                                            </div>
                                        </div>
                                        <div className="process-content ps-30px last-paragraph-no-margin">
                                            <span className="d-block fw-700 text-dark-gray mb-5px fs-18">{processData.Step3Head}</span>
                                            <p className="w-90 lg-w-100 lh-32">{processData.Step3Details}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end process step item */}
                            </div>
                        </div>
                        <div className="col-lg-7 offset-xxl-1 position-relative md-mb-30px sm-mb-15"
                            data-anime='{ "translateX": [0, 0], "opacity": [0,1], "duration": 1200, "delay": 0, "staggervalue": 150, "easing": "easeOutQuad" }'>
                            <img src="assets/images/demo-corporate-10.webp" className="position-relative z-index-9 top-40px" alt="" />
                            <img src="assets/images/demo-corporate-03.png" className="absolute-middle-center xs-w-95" alt="" />
                            <img src="assets/images/demo-corporate-05.png" className="position-absolute top-50px left-0px xs-left-15px"
                                data-bottom-top="transform: translateY(-50px)" data-top-bottom="transform: translateY(50px)"
                                alt="" />
                            <img src="assets/images/demo-corporate-06.webp" className="position-absolute top-150px left-30"
                                data-bottom-top="transform: translateY(30px)" data-top-bottom="transform: translateY(-30px)"
                                alt="" />
                            <img src="assets/images/demo-corporate-07.webp" className="position-absolute top-50px right-30"
                                data-bottom-top="transform: translateY(-50px)" data-top-bottom="transform: translateY(50px)"
                                alt="" />
                            <img src="assets/images/demo-corporate-08.png"
                                className="position-absolute top-100px right-0px xs-right-15px"
                                data-bottom-top="transform: translateY(30px)" data-top-bottom="transform: translateY(-30px)"
                                alt="" />
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 text-center align-items-center" data-anime='{ "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <div className="bg-base-color fw-700 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12 me-10px sm-m-5px d-inline-block align-middle">hurray</div>
                            <div className="fs-18 fw-500 text-dark-gray d-inline-block align-middle">{processData.Line1} <a href="contact.html" className="text-dark-gray text-decoration-line-bottom fw-700">{processData.Link}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end section */}
            {/* start section */}
            <section className="bg-gradient-quartz-white border-radius-6px lg-border-radius-0px">
                <div className="container background-no-repeat background-position-top"
                    style={{ backgroundImage: 'https://craftohtml.themezaa.com/images/demo-corporate-bg-02.png' }}>
                    <div className="row justify-content-center mb-3">
                        <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9 text-center" data-anime='{ "el": "childs", "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <h3 className="text-dark-gray fw-700 ls-minus-1px">Trusted by the Geninue People</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-3">
                        <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9 text-center" data-anime='{ "el": "childs", "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <h3 className="text-dark-gray fw-700 ls-minus-1px">Testimonials</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center mb-6 sm-mb-8" data-anime='{ "el": "childs", "translateY": [0, 0], "opacity": [0,1], "duration": 1200, "delay": 100, "staggervalue": 150, "easing": "easeOutQuad" }'>
                        <div className="col-12 position-relative ps-8 pe-8 lg-ps-15px lg-pe-15px">
                            <div className="swiper magic-cursor testimonials-style-06" data-slider-options='{ "loop": true, "autoplay": { "delay": 4000, "disableOnInteraction": false }, "keyboard": { "enabled": true, "onlyInViewport": true }, "navigation": { "nextEl": ".swiper-button-next-nav", "prevEl": ".swiper-button-previous-nav", "effect": "fade" } }'>
                                <div className="swiper-wrapper">
                                    {/* start testimonial item */}
                                    {testoData.length > 0 && testoData.map((record, index) => (
                                        <div key={index} className="swiper-slide">
                                            <div className="row align-items-center justify-content-center">
                                                {record.Image ? <div className="col-8 col-md-4 col-sm-6 text-center md-mb-30px">
                                                    <img alt="" src="https://via.placeholder.com/270x245" />
                                                </div> : ""}
                                                <div className={record.Image ? "col-lg-5 col-md-7 last-paragraph-no-margin text-center text-md-start" : "col-lg-9 col-md-9 last-paragraph-no-margin text-center text-md-start"}>
                                                    <a href="#" className="mb-15px d-block"><img src="images/logo-monday-dark-blue-01.svg" className="h-35px" alt="" /></a>
                                                    <span className="mb-5px d-table fs-18 lh-30 fw-500 text-dark-gray">{record.Details}</span>
                                                    <span className="fs-15 text-uppercase fw-800 text-base-color ls-1px">{record.Name}</span>
                                                </div>
                                            </div>
                                        </div>))}
                                    {/* end testimonial item */}
                                </div>
                                {/* start slider navigation */}
                                <div className="swiper-button-previous-nav swiper-button-prev md-left-0px"><i className="feather icon-feather-arrow-left icon-extra-medium text-dark-gray"></i></div>
                                <div className="swiper-button-next-nav swiper-button-next md-right-0px"><i className="feather icon-feather-arrow-right icon-extra-medium text-dark-gray"></i></div>
                                {/* end slider pagination */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end section */}
            {/* start section */}
            {video == "" ? "" :
                <section className="p-0">
                    <div className="container">
                        <div className="row justify-content-center" data-anime='{ "translateY": [0, 0], "opacity": [0,1], "duration": 1200, "delay": 0, "staggervalue": 150, "easing": "easeOutQuad" }'>
                            <div className="col-12">
                                <div className="border-radius-6px h-450px md-h-350px sm-h-400px d-flex flex-wrap align-items-center justify-content-center overflow-hidden cover-background box-shadow-quadruple-large pt-15" style={{ backgroundImage: 'https://via.placeholder.com/1315x450' }}>
                                    <div className="opacity-full-dark bg-gradient-regal-blue-transparent"></div>
                                    <div className="row justify-content-center m-0">
                                        <div className="col-lg-7 col-md-8 z-index-1 text-center text-md-start sm-mb-20px">
                                            <h3 className="text-white mb-0 fw-400 fancy-text-style-4">Our Experienced to serve you <span className="fw-600" data-fancy-text='{ "effect": "rotate", "string": ["Excellent Apartments!", "Excellent Shops!"] }'></span>
                                            </h3>
                                        </div>
                                        <div className="col-md-2 position-relative z-index-1 text-center sm-mb-20px">
                                            <a href={video} className="position-relative d-inline-block text-center border border-2 border-color-white rounded-circle video-icon-box video-icon-large popup-youtube">
                                                <span>
                                                    <span className="video-icon">
                                                        <i className="fa-solid fa-play fs-20 text-white"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="w-100 text-center position-relative mt-auto pt-20px pb-25px ps-15px pe-15px border-top border-color-transparent-white-light">
                                        <div className="fs-14 text-uppercase text-white fw-600 ls-05px">Let's make something great work together. <a href="contact.html" className="text-decoration-line-bottom text-white">Contact Us Now!</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/* end section */}


        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { data: state.dCandidate.list };
};

const mapActionToProps = {
    fetchAllCandidates: actions.fetchall
}

export default connect(mapStateToProps, mapActionToProps)(Home);