import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProjectDetails = (props) => {
    const params = useParams();
    const name = params.name.replace("-", " ");
    const [pageData, setPageData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [featureData, setFeatureData] = useState([]);
    const [floorPlanData, setFloorPlanData] = useState([]);
    const [description, setDescription] = useState("");
    const [propListData, setPropListData] = useState([]);
    const [testoData, setTestData] = useState([]);

    const settings = { dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, autoplay: true };
    const propSlide = { dots: true, infinite: true, speed: 500, slidesToShow: 3, slidesToScroll: 1, autoplay: true };

    useEffect(() => {
        props.fetchData({ name });
        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad", translateY: [30, 0]
        });

    }, [])

    useEffect(() => {
        const makeRequest = async () => {
            let jsn = await props.data;
            if (jsn != null) {
                if (jsn.PropertyDetails != null) {
                    setPageData(JSON.parse(jsn.PropertyDetails));
                    setDescription(jsn.Description);
                }
                if (jsn.Company != null) {
                    if (JSON.parse(jsn.Company).length) {
                        setCompanyData(JSON.parse(jsn.Company)[0]);
                    }
                }
                if (jsn.Images != null) {
                    let parsedArray = JSON.parse(jsn.Images);
                    setImageData(parsedArray);
                    console.log(parsedArray);
                }
                if (jsn.Features != null) {
                    let parsedArray = JSON.parse(jsn.Features);
                    setFeatureData(parsedArray);
                }
                if (jsn.FloorPlans != null) {
                    let parsedArray = JSON.parse(jsn.FloorPlans);
                    setFloorPlanData(parsedArray);
                }
                if (jsn.PropertiesList != null) {
                    let parsedArray = JSON.parse(jsn.PropertiesList);
                    setPropListData(parsedArray);
                    console.log(parsedArray);
                }
                if (jsn.Testimonials != null) {
                    let parsedArray = JSON.parse(jsn.Testimonials);
                    setTestData(parsedArray);
                }
            }
        }
        makeRequest();
    }, [props.data]);

    function CheckImage(path) {
        axios.get(path).then(() => { return true; }).catch(() => { return false; });
    }

    return (
        <Fragment>
            <section className="cover-background page-title-big-typography ipad-top-space-margin">
                <div className="container">
                    <div className="row align-items-center align-items-lg-end justify-content-center extra-very-small-screen g-0">
                        <div className="col-xl-7 col-lg-8 position-relative page-title-extra-small md-mb-30px md-mt-auto" data-anime>
                            <h3 className="alt-font fw-600 text-dark-gray mb-15px ls-minus-1px">{pageData.Name}</h3>
                            <h1 className="mb-0 d-flex"><i className="feather icon-feather-map-pin icon-extra-medium text-base-color me-5px"></i>{pageData.Address}</h1>
                        </div>
                        <div className="col-lg-3 offset-xl-2 offset-lg-1 border-start border-2 border-color-base-color ps-40px sm-ps-25px md-mb-auto">
                            <h4 className="text-dark-gray fw-700 alt-font mb-5px">₹ {pageData.Rate}</h4>
                            {/* <span className="fw-500 fs-18">$3,700 - Per sq. ft.</span> */}
                        </div>
                    </div>
                </div>
            </section>
            {/*  end page title */}
            {/*  start section */}
            <section className="p-0 overflow-hidden">
                <div className="container-fluid p-0">
                    <div className="row row-cols-1 justify-content-center">
                        {/*  start content carousal item */}
                        <div className="col">
                            <div className="swiper magic-cursor slider-four-slide swiper-dark-pagination swiper-pagination-style-3" data-slider-options='{ "slidesPerView": 1, "spaceBetween": 20, "loop": true, "pagination": { "el": ".slider-four-slide-pagination", "clickable": true }, "autoplay": { "delay": 3000, "disableOnInteraction": false }, "navigation": { "nextEl": ".slider-one-slide-next-1", "prevEl": ".slider-one-slide-prev-1" }, "keyboard": { "enabled": true, "onlyInViewport": true }, "breakpoints": { "1200": { "slidesPerView": 3 }, "992": { "slidesPerView": 3 }, "768": { "slidesPerView": 2 } }, "effect": "slide" }'>
                                {/* <div className="swiper-wrapper"> */}
                                    <Slider className="swiper magic-cursor slider-one-slide" {...settings}>
                                        {imageData.map((record, index) => (
                                            <div key={index} className="swiper-slide">
                                                <img src={process.env.REACT_APP_API_IMAGE + "/Property/" + record.Image} alt="" className="w-100" style={{ height: '500px' }} />
                                            </div>
                                        ))}
                                    </Slider>
                                {/* </div> */}
                                {/*  <div className="slider-one-slide-prev-1 icon-very-small bg-white h-40px w-40px swiper-button-prev slider-navigation-style-01"><i className="feather icon-feather-arrow-left fs-20 text-light-gray"></i></div>
                            <div className="slider-one-slide-next-1 icon-very-small bg-white h-40px w-40px swiper-button-next slider-navigation-style-01"><i className="feather icon-feather-arrow-right fs-20 text-light-gray"></i></div> */}
                                {/*  end slider navigation */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end section */}
            {/*  start section */}
            <section className="pt-30px pb-30px border-bottom border-color-extra-medium-gray">
                <div className="container">
                    <div className="row row-cols-1 row-cols-lg-3 row-cols-sm-2">
                        <div className="col text-center border-end xs-border-end-0 border-color-extra-medium-gray alt-font md-mb-15px">
                            <span className="fs-19 text-dark-gray fw-600">Property size:</span> {pageData.PropSize} sq ft
                        </div>
                        <div className="col text-center border-end md-border-end-0 border-color-extra-medium-gray alt-font md-mb-15px">
                            <span className="fs-19 text-dark-gray fw-600">Year built:</span> {pageData.YearBuilt}
                        </div>
                        <div className="col text-center border-end xs-border-end-0 border-color-extra-medium-gray alt-font sm-mb-15px">
                            <span className="fs-19 text-dark-gray fw-600">Status:</span> {pageData.Status}
                        </div>
                    </div>
                </div>
            </section>
            {/*  end section */}
            {/*  start section */}
            <section className="position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 md-mb-50px">
                            <div className="row mb-15px">
                                <div className="col-12">
                                    <span className="text-dark-gray fs-24 fw-600 alt-font mb-15px d-block">Property description</span>
                                    <div dangerouslySetInnerHTML={{ __html: description }}></div>
                                </div>
                            </div>
                            <div className="row g-0">
                                <div className="col bg-very-light-gray p-35px lg-ps-15px lg-pe-15px border-radius-6px fs-16">
                                    <div className="row row-cols-2 row-cols-md-4 row-cols-sm-2">
                                        <div className="col text-center border-end border-color-extra-medium-gray sm-mb-30px">
                                            <img src="/assets/images/demo-real-estate-icon-bed.svg" className="w-50px mb-15px" alt="" />
                                            <span className="text-dark-gray d-block lh-20">Start From : {pageData.StartBed}</span>
                                        </div>
                                        <div className="col text-center border-end sm-border-end-0 border-color-extra-medium-gray sm-mb-30px">
                                            <img src="/assets/images/demo-real-estate-icon-bath.svg" className="w-50px mb-15px" alt="" />
                                            <span className="text-dark-gray d-block lh-20">Start From : {pageData.StartBath}</span>
                                        </div>
                                        <div className="col text-center border-end border-color-extra-medium-gray">
                                            <img src="/assets/images/demo-real-estate-icon-car.svg" className="w-50px mb-15px" alt="" />
                                            <span className="text-dark-gray d-block lh-20">{pageData.Parking}</span>
                                        </div>
                                        <div className="col text-center">
                                            <img src="/assets/images/demo-real-estate-icon-swimming.svg" className="w-50px mb-15px" alt="" />
                                            <span className="text-dark-gray d-block lh-20">{pageData.PropType}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-7">
                                <div className="col-12">
                                    <span className="text-dark-gray fs-24 fw-600 alt-font mb-25px d-block">Specification</span>
                                </div>
                                <div className="col-xl-6">
                                    <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                        <div className="col">
                                            {/*  start features box item */}
                                            <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                                <div className="feature-box-icon me-10px">
                                                    <img src="images/demo-real-estate-property-details-08.svg" className="w-25px" alt="" />
                                                </div>
                                                <div className="feature-box-content">
                                                    <span className="text-dark-gray">Floors:</span>
                                                </div>
                                            </div>
                                            {/*  end features box item */}
                                        </div>
                                        <div className="col">{pageData.Floors}</div>
                                    </div>
                                    <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                        <div className="col">
                                            {/*  start features box item */}
                                            <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                                <div className="feature-box-icon me-10px">
                                                    <img src="images/demo-real-estate-property-details-09.svg" className="w-25px" alt="" />
                                                </div>
                                                <div className="feature-box-content">
                                                    <span className="text-dark-gray">Apartments:</span>
                                                </div>
                                            </div>
                                            {/*  end features box item */}
                                        </div>
                                        <div className="col">{pageData.Apartments}</div>
                                    </div>
                                    <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                        <div className="col">
                                            {/*  start features box item */}
                                            <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                                <div className="feature-box-icon me-10px">
                                                    <img src="images/demo-real-estate-property-details-12.svg" className="w-25px" alt="" />
                                                </div>
                                                <div className="feature-box-content">
                                                    <span className="text-dark-gray">Commercials:</span>
                                                </div>
                                            </div>
                                            {/*  end features box item */}
                                        </div>
                                        <div className="col">{pageData.Commercial}</div>
                                    </div>
                                    <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                        <div className="col">
                                            {/*  start features box item */}
                                            <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                                <div className="feature-box-icon me-10px">
                                                    <img src="images/demo-real-estate-property-details-15.svg" className="w-25px" alt="" />
                                                </div>
                                                <div className="feature-box-content">
                                                    <span className="text-dark-gray">Residence start from:</span>
                                                </div>
                                            </div>
                                            {/*  end features box item */}
                                        </div>
                                        <div className="col">{pageData.Res_start_from}</div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                        <div className="col">
                                            {/*  start features box item */}
                                            <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                                <div className="feature-box-icon me-10px">
                                                    <img src="images/demo-real-estate-property-details-06.svg" className="w-25px" alt="" />
                                                </div>
                                                <div className="feature-box-content">
                                                    <span className="text-dark-gray">Rooms per floor:</span>
                                                </div>
                                            </div>
                                            {/*  end features box item */}
                                        </div>
                                        <div className="col">{pageData.Rooms_per_floor}</div>
                                    </div>
                                    <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                        <div className="col">
                                            {/*  start features box item */}
                                            <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                                <div className="feature-box-icon me-10px">
                                                    <img src="images/demo-real-estate-property-details-06.svg" className="w-25px" alt="" />
                                                </div>
                                                <div className="feature-box-content">
                                                    <span className="text-dark-gray">Wings:</span>
                                                </div>
                                            </div>
                                            {/*  end features box item */}
                                        </div>
                                        <div className="col">{pageData.Wings}</div>
                                    </div>
                                    <div className="row g-0 align-items-center lg-mb-15px lg-pb-15px lg-border-bottom border-color-extra-medium-gray">
                                        <div className="col">
                                            {/*  start features box item */}
                                            <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                                <div className="feature-box-icon me-10px">
                                                    <img src="images/demo-real-estate-property-details-09.svg" className="w-25px" alt="" />
                                                </div>
                                                <div className="feature-box-content">
                                                    <span className="text-dark-gray">Total floors:</span>
                                                </div>
                                            </div>
                                            {/*  end features box item */}
                                        </div>
                                        <div className="col">03</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-7">
                                <div className="col-12">
                                    <span className="text-dark-gray fs-24 fw-600 alt-font mb-25px d-block">Location</span>
                                </div>
                                <div className="col-12">
                                    {/* <div id="map" className="map small-screen border-radius-6px" data-map-options='{ "lat": , "lng": 144.962312, "style": "Dark", "marker": { "type": "HTML", "color": "#06af47" }, "popup": { "defaultOpen": true, "html": "<div className=infowindow><strong className=\"mb-3 d-inline-block alt-font\">Crafto Real Estate</strong><p className=\"alt-font\">401 Broadway, 24th Floor, Orchard View, London, UK</p></div><div className=\"google-maps-link alt-font\"> <a aria-label=\"View larger map\" target=\"_blank\" jstcache=\"31\" href=\"https://maps.google.com/maps?ll=-37.805688,144.962312&amp;z=17&amp;t=m&amp;hl=en-US&amp;gl=IN&amp;mapclient=embed&amp;cid=13153204942596594449\" jsaction=\"mouseup:placeCard.largerMap\">VIEW LARGER MAP</a></div>" } }'></div> */}
                                    <iframe src={pageData.Location} width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="row mt-7">
                                <div className="col-12">
                                    <span className="text-dark-gray fs-24 fw-600 alt-font mb-15px d-block">Features</span>
                                </div>
                                <div className="col-12 col-sm-12">
                                    {/*  start list style */}
                                    <ul className="list-style-02 ps-0 mb-0">
                                        {featureData.length > 0 && featureData.map((record, index) => (
                                            <li key={index}><i className="bi bi-check-circle icon-small me-10px"></i>{record.Feature}</li>
                                        ))}
                                    </ul>
                                    {/*  end list style */}
                                </div>
                            </div>
                            <div className="row mt-7">
                                <Slider className="swiper magic-cursor slider-one-slide" {...settings}>
                                    {floorPlanData.map((record, index) => (
                                        <div key={index} className="col-12">
                                            <span className="text-dark-gray fs-24 fw-600 alt-font mb-25px d-block">{record.Header}</span>
                                            <img src={process.env.REACT_APP_API_IMAGE + "/Property/" + record.Image} className="border-radius-6px" alt="" />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                        {/*  start sticky */}
                        <div className="col-xl-4 offset-xl-1 col-lg-5">
                            <div className="bg-base-color-light border-radius-6px position-sticky top-120px">
                                <div className="bg-base-color border-radius-6px feature-box feature-box-left-icon-middle overflow-hidden icon-with-text-style-08 ps-35px pe-35px pt-25px pb-20px xs-p-25px">
                                    {/*  start features box item */}
                                    <div className="feature-box-icon feature-box-icon-rounded w-110px h-110px overflow-visible me-20px position-relative">
                                        <img src="/assets/images/logo/sky_homes_white_240323.png" className="rounded-circle" alt="" />
                                        <span className="animation-zoom d-inline-block bg-orange border border-2 border-color-white h-20px w-20px border-radius-100 position-absolute right-0px top-5px"></span>
                                    </div>
                                    {/*  end features box item */}
                                    {/*  start features box item */}
                                    <div className="feature-box-content last-paragraph-no-margin">
                                        <span className="text-white alt-font fw-600 fs-20 d-block">Sky Home Developers</span>
                                    </div>
                                    {/*  end features box item */}
                                    {/*  start social icon */}
                                    <div className="elements-social social-icon-style-02 mt-5px w-100 text-start text-lg-center">
                                        <ul className="medium-icon">
                                            <li className="m-0"><a className="facebook text-white" href="https://www.facebook.com/SKYDevlprs" target="_blank"><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li className="m-0"><a className="instagram text-white" href="https://www.instagram.com/skyhomes_devlopers1" target="_blank"><i className="fa-brands fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                    {/*  end social icon */}
                                </div>
                                <div className="ps-45px pe-45px pt-35px pb-45px xs-p-25px contact-form-style-01 mt-0">
                                    <div className="mb-20px last-paragraph-no-margin">
                                        <p className="mb-0 alt-font fw-500 text-dark-gray"><i className="feather icon-feather-phone-call icon-small text-base-color me-10px"></i><span className="fw-600 w-16 sm-w-15 xs-w-20 d-inline-block">Phone:</span><a href="tel:+9186220 09862" className="text-dark-gray text-base-color-hover">+91 86220 09862</a></p>
                                        <p className="alt-font fw-500 text-dark-gray"><i className="feather icon-feather-mail icon-small text-base-color me-10px"></i><span className="fw-600 w-16 sm-w-15 xs-w-20 d-inline-block">Email:</span><a href="mailto:skyhomedevelopers@gmail.com" className="text-dark-gray text-decoration-line-bottom">skyhomedevelopers@gmail.com</a></p>
                                    </div>
                                    <span className="alt-font fs-20 fw-600 text-dark-gray d-block mb-25px">Leave your message here</span>
                                    {/*  start contact form */}
                                    <form action="email-templates/contact-form.php" method="post">
                                        <div className="position-relative form-group mb-15px">
                                            <span className="form-icon"><i className="bi bi-emoji-smile"></i></span>
                                            <input type="text" name="name" className="form-control border-color-white box-shadow-large required" placeholder="Your name*" />
                                        </div>
                                        <div className="position-relative form-group mb-15px">
                                            <span className="form-icon"><i className="bi bi-envelope"></i></span>
                                            <input type="email" name="email" className="form-control border-color-white box-shadow-large required" placeholder="Your email address*" />
                                        </div>
                                        <div className="position-relative form-group mb-15px">
                                            <span className="form-icon"><i className="bi bi-telephone-outbound"></i></span>
                                            <input type="tel" name="phone" className="form-control border-color-white box-shadow-large" placeholder="Your phone" />
                                        </div>
                                        <div className="position-relative form-group form-textarea">
                                            <span className="form-icon"><i className="bi bi-chat-square-dots"></i></span>
                                            <textarea placeholder="Your message" name="comment" className="form-control border-color-white box-shadow-large" rows="3"></textarea>
                                            <input type="hidden" name="redirect" value="" />
                                            <button className="btn btn-small btn-round-edge btn-base-color mt-20px submit " type="submit">Send message</button>
                                            <div className="form-results mt-20px d-none"></div>
                                        </div>
                                    </form>
                                    {/*  end contact form */}
                                </div>
                            </div>
                        </div>
                        {/*  end sticky */}
                    </div>
                </div>
            </section>
            {/*  end section */}
            {/*  start section */}
            <section className="bg-gradient-very-light-gray">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-12 text-center">
                            <h3 className="alt-font text-dark-gray fw-500 ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">Similar <span className="fw-700 text-highlight d-inline-block">properties<span className="bg-base-color h-10px bottom-5px opacity-3 separator-animation"></span></span></h3>
                        </div>
                    </div>
                    <div className="row row-cols-12 row-cols-xl-12 row-cols-lg-12 row-cols-md-12 justify-content-center">
                        {/*  start box item */}
                        <Slider className="swiper magic-cursor slider-one-slide" {...propSlide}>
                            {propListData.map((record, index) => (
                                <div key={index} className="col mb-30px">
                                    <div className="border-radius-6px overflow-hidden box-shadow-large">
                                        <div className="image position-relative">
                                            <Link to={"/project/" + record.Name.replace(/ /g, "-")}>
                                                <img src={process.env.REACT_APP_API_IMAGE + "/Property/" + record.Image} alt=""/>
                                            </Link>
                                            <div className="col-auto bg-base-color border-radius-50px ps-15px pe-15px text-uppercase alt-font fw-600 text-white fs-12 lh-24 position-absolute left-20px top-20px">{record.Status}</div>
                                        </div>
                                        <div className="bg-white">
                                            <div className="content ps-40px pe-40px pt-35px pb-35px md-p-25px border-bottom border-color-transparent-dark-very-light">
                                                <div className="d-flex align-items-center">
                                                    <Link to={"/project/" + record.Name.replace(/ /g, "-")} className="alt-font text-dark-gray fw-700 fs-22 me-10px">{record.Name}</Link>
                                                </div>
                                                <p className="mb-20px">{record.Address}</p>
                                                <div className="row g-0">
                                                    <div className="col">
                                                        <div className="d-flex align-items-center">
                                                            <img src="images/demo-real-estate-icon-bed-small.svg" className="me-5px h-20px" alt="" />
                                                            <span className="fw-600 alt-font text-dark-gray">{record.Bed}</span>
                                                        </div>
                                                        <span className="d-block lh-18 fs-15">Bedrooms</span>
                                                    </div>
                                                    <div className="col">
                                                        <div className="d-flex align-items-center">
                                                            <img src="images/demo-real-estate-icon-bath-small.svg" className="me-5px h-20px" alt="" />
                                                            <span className="fw-600 alt-font text-dark-gray">{record.Bath}</span>
                                                        </div>
                                                        <span className="d-block lh-18 fs-15">Bathrooms</span>
                                                    </div>
                                                    <div className="col">
                                                        <div className="d-flex align-items-center">
                                                            <img src="images/demo-real-estate-icon-size-small.svg" className="me-5px h-20px" alt="" />
                                                            <span className="fw-600 alt-font text-dark-gray">{record.SqFt} ft<sup>2</sup></span>
                                                        </div>
                                                        <span className="d-block lh-18 fs-15">Living area</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row ps-35px pe-35px pt-20px pb-20px md-ps-25px md-pe-25px align-items-center">
                                                <div className="col">
                                                    <Link to={"/project/" + record.Name.replace(/ /g, "-")} className="btn btn-dark-gray btn-very-small btn-round-edge fw-600">View details</Link>
                                                </div>
                                                <div className="col text-end">
                                                    <span className="fs-24 alt-font text-dark-gray fw-700 mb-0">₹ {record.Amount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        {/*  end box item */}
                    </div>
                </div>
            </section>
            {/*  end section */}
            {/*  start section */}
            <section className="pt-0 pb-30px position-relative overlap-height overflow-hidden">
                <div className="container-fluid overlap-gap-section">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <h3 className="alt-font text-dark-gray fw-500 ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">Best review by <span className="fw-700 text-highlight d-inline-block">happy customer<span className="bg-base-color h-10px bottom-5px opacity-3 separator-animation"></span></span></h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 p-0 review-style-09">
                            <div className="swiper slider-shadow-left-right lg-slider-shadow-none magic-cursor lg-ps-15px lg-pe-15px" data-slider-options='{ "slidesPerView": 1, "spaceBetween": 30, "loop": true, "autoplay": { "delay": 2000, "disableOnInteraction": false },  "pagination": { "el": ".slider-three-slide-pagination", "clickable": true, "dynamicBullets": true }, "navigation": { "nextEl": ".slider-three-slide-next", "prevEl": ".slider-three-slide-prev" }, "keyboard": { "enabled": true, "onlyInViewport": true }, "breakpoints": { "1400": { "slidesPerView": 4 }, "1200": { "slidesPerView": 3 }, "768": { "slidesPerView": 2 } }, "effect": "slide" }'>
                                {/*  start slider item */}
                                <Slider className="swiper-wrapper pt-30px pb-30px" {...settings}>
                                    {testoData.length > 0 && testoData.map((record, index) => (

                                        <div key={index} className="swiper-slide">
                                            {/*  start review item */}
                                            <div className="border-radius-10px bg-white box-shadow-double-large">
                                                <div className="d-flex align-items-center p-40px md-p-25px">
                                                    {/* <img className="rounded-circle w-110px h-110px md-w-80px md-h-80px me-25px md-me-20px" src="https://via.placeholder.com/148x148" alt="" /> */}
                                                    <div>
                                                        <p className="mb-10px lh-32">{record.Details}</p>
                                                        <div className="d-inline-block bg-orange text-white border-radius-3px ps-10px pe-10px fs-13 ls-minus-2px lh-28 me-10px md-me-5px sm-me-10px align-middle">
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star-half-stroke"></i>
                                                        </div>
                                                        <span className="fw-600 alt-font text-dark-gray d-inline-block">{record.Name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*  end review item */}
                                        </div>
                                    ))}
                                </Slider>
                                {/*  end slider item */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment >
    )
}

const mapStateToProps = (state) => {
    return { data: state.dCandidate.list };
};

const mapActionToProps = {
    fetchData: actions.fetchallpropertyByName
}

export default connect(mapStateToProps, mapActionToProps)(ProjectDetails);