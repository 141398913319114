import axios from 'axios';
import { fetchallProperty } from './dCandidate';

// const baseUrl = "https://localhost:7153/api/Front/"
const baseUrl = "https://test.recruiterserp.com/API/Front/"
export default {
    dCandidate(url = baseUrl){
        return {
            fetchAll        : () => axios.get(url + "GetHome?token=23594921"),
            fetchAllAbout   : () => axios.get(url + "GetAbout?token=23594921"),
            fetchAllProperty   : () => axios.get(url + "GetProperties?token=23594921"),
            fetchAllContact   : () => axios.get(url + "GetContact?token=23594921"),
            fetchAllPropertyByName   : name => axios.get(url + "GetProjectDetails?name="+name.name+"&token=23594921"),
            fecthById   : id => axios.get(url + id),
            create      : newRecord => axios.post(url, newRecord),
            update      : (id, updateRecord) => axios.put(url + id, updateRecord),
            delete      : id => axios.delete(url + id)
        }
    }
}