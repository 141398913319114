import logo from './logo.svg';
//import './App.css';
import { store } from "./actions/store";
import { Provider } from 'react-redux';
//import DCandidates from './components/DCandidates';
import Header from './Header';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import ProjectDetails from './components/ProjectDetails';

function App() {
  return (
    <Provider store={store}>
      <Header></Header>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/project/:name" element={<ProjectDetails />} />
      </Routes>
    </Provider>
  );
}

export default App;
