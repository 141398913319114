import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';

const Header = () => {
    return (
        <div>
            <header>
                <nav className="navbar navbar-expand-lg header-light bg-white disable-fixed">
                    <div className="container-fluid">
                        <div className="col-auto col-xl-3 col-lg-2 me-lg-0 me-auto">
                            <Link className="navbar-brand" to={"/"}>
                                <img src="/assets/images/logo/logo.png" data-at2x="/assets/images/logo/logo.png" alt="" className="default-logo" />
                                <img src="/assets/images/logo/logo.png" data-at2x="/assets/images/logo/logo.png" alt="" className="alt-logo" />
                                <img src="/assets/images/logo/logo.png" data-at2x="/assets/images/logo/logo.png" alt="" className="mobile-logo" />
                            </Link>
                        </div>
                        <div className="col-auto menu-order position-static">
                            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><Link className="nav-link" to={"/"}>Home</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to={"/about"}>About</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to={"/projects"}>Projects</Link></li>
                                    {/* <li className="nav-item"><Link className="nav-link" to={"/events"}>Events & Gallery</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to={"/careers"}>Careers</Link></li> */}
                                    <li className="nav-item"><Link className="nav-link" to={"/contact"}>Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}
export default Header;